/* Card styles (default screen view) */
.cashout-card {
    /* margin-left: 10px;
    font-family: Arial, sans-serif;
    text-align: left; */
    margin-left: 10px;
  margin-bottom: 20px; /* Adds space between multiple cards */
  font-family: Arial, sans-serif;
  text-align: left;
  }
  
  .cashout-card h1,
  .cashout-card h3,
  .cashout-card p {
    margin: 5px 0;
    line-height: 1.5;
  }
  
  /* Hide print container by default */
  .cash-print-container {
    display: none;
  }
  
  /* Print-specific styles */
  @media print {
    /* Show print container */
    .cash-print-container {
      display: block;
      text-align: center;
      margin: 0 auto;
      font-family: Arial, sans-serif;
    }
  
    /* Hide card during printing */
    .cashout-card {
      display: none;
    }
  
    /* Logo styling */
    .print-logo {
      text-align: center;
      /* margin-bottom: 10px; */
    }
  
    .print-logo img {
      width: 200px;
      height: 200px;
      object-fit: contain;
      margin: 0 auto;
    }
  
    /* Print content styling */
    .print-content p {
      font-size: 18px; /* Larger font size for better readability */
      margin: 10px 0;
      line-height: 1.8;
    }
  
    /* Hide the print button */
    button {
      display: none;
    }
  }
  