/* Hide logo and title by default */
.print-logo,
.print-title {
  display: none;
}

/* Card-like styling for the content */
.print-card {
  border-radius: 8px;
  padding: 20px;
  margin: 20px;
}

/* Print-specific styles */
@media print {
  /* Show logo and title only during printing */
  .print-logo,
  .print-title {
    display: block;
    text-align: center;
    margin: 0; /* Reset any default margins */
    padding: 0; /* Reset any default padding */
  }

  .print-logo {
    width: 200px;
    height: 200px;
    object-fit: contain; /* Prevent squashing or distortion */
    margin: 0 auto; /* Center the image */
  }

  .print-title {
    /* margin-top: 5px; Reduce space between logo and title */
    margin-bottom: 10px; /* Add slight spacing below the title */
    font-size: 24px; /* Adjust font size if needed */
    font-weight: bold;
  }

  /* Hide the print button during printing */
  .print-button {
    display: none;
  }

  /* Ensure the card looks good in print */
  .print-card {
    border: none;
    box-shadow: none;
    padding: 0;
    margin: 0;
    font-size: 18px; /* Adjust font size if needed */
  }
}
