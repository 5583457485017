.customer-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-group {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-around;
}

.form-group label {
  flex: 1;
  margin: 0;
  text-align: left; /* Align text to the left */
}

.dotted-border {
  border-style: dotted; /* Default border style */
  border-width: 0 0 1px 0; /* No border by default */
  border-color: #000; /* Transparent border by default */
  padding: 5px;
  flex: 2; /* Makes the input box take more space */
}

.dotted-border:focus {
  border-style: dotted;
  border-width: 0 0 1px 0; /* Only bottom border is dotted */
  border-color: #000; /* Adjust color as needed */
  outline: none; /* Remove default outline */
}
