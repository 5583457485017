.print-logo {
  display: none;
  margin: 0 auto;
}

@media print {
  .print-logo {
      display: block !important;
      margin: 0 auto;
  }

  .print-container {
      padding: 0px;
      margin: 0 auto;
      max-width: 350px;
      text-align: left;
      border: none;
      background-color: #fff;
      box-shadow: none;
      font-family: Arial, sans-serif;
  }

  .print-container div {
      padding: 5px 0;
      font-size: 10px;
      line-height: 1.2;
  }

  .print-container img {
      width: 45vw;
      margin: 0 auto 10px;
      display: block;
  }

  .print-container h6,
  .print-container span,
  .print-container p {
      font-size: 12px;
      line-height: 1.2;
  }

  .print-box-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0;
      padding: 5px 0;
      border-bottom: 1px solid #e0e0e0;
      font-size: 12px;
      line-height: 1.2;
  }

  body {
      margin: 0;
      padding: 0;
      -webkit-print-color-adjust: exact;
      color-adjust: exact;
  }

  button,
  .MuiButtonBase-root {
      display: none !important;
  }
}
